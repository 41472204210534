// This deployment is intended for production.

import { EnvironmentEnum } from 'src/app/shared/enums/environmentsEnum';

export const environment = {
  production: true,
  backendApiUrl: 'https://parking-cc.tcsgroup.ch/parkingservice',
  backendApiVersion: 1,
  clientId: '96e6e2ab-3ac3-4a94-8b16-909fb4fb8a38',
  tenantID: 'b6ad2cf5-3533-463d-8986-d1c95ab386fb',
  get authority() { return `https://login.microsoftonline.com/${this.tenantID}` },
  postLogoutRedirectUri: 'https://parking-cc.tcsgroup.ch',
  defaultSettings: {
    table: {
      pageSize: 50,
      pageOptions: [50, 100, 150]
    },
    dateMomentFormat: "DD.MM.YYYY"
  },
  isLocal: false,
  lokaliseProjectId: '477393515f8e4893035fc8.81283625',
  name: EnvironmentEnum.Production
};
